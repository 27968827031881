<template>
  <b-card class="mb-4 border-0">
    <b-spinner v-if="loading"></b-spinner>
    <template #header>
      <strong>SKU distribution configurations</strong>
      <div class="info-button-wrapper float-right">
        <div id="info-default-configs" v-b-hover="handleInfoHover">
          <b-icon v-if="isHovered" icon="info-circle-fill" variant="info" scale="2"></b-icon>
          <b-icon v-else icon="info-circle" scale="2"></b-icon>
        </div>
        <b-tooltip
          id="info-default-configs-tooltip"
          target="info-default-configs"
          triggers="hover"
          placement="left"
          custom-class="dark-tooltip">
          Distribution % of each SKU represents the probability of it being chosen when a new order is created using that product type
        </b-tooltip>
      </div>
    </template>
    <b-row v-if="!loading" class="d-flex align-items-center pt-2 pr-5 pb-4 pl-0" style="width:1024px;">
      <strong class="col-2">Product type:</strong>
      <b-form-select
          class="col-2"
          v-model="selected"
          :options="productTypeOptions"
          v-on:change="triggerProductTypeChange(selected)">
      </b-form-select>
    </b-row>
    <table v-if="!loading" class="table" style="max-width:900px; min-height:100px;">
      <thead class="thead-light">
      <tr>
          <th class="text-center" scope="col">SKU</th>
          <th class="text-center" scope="col">Stock</th>
          <th class="text-center" scope="col">Distribution</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="option in skuOptions" :key="option.id">
          <td class="align-middle text-center">{{ option.sku }}</td>
          <td class="align-middle text-center">{{ option.stock }}</td>
          <td class="align-middle text-center">{{ option.distribution }} %</td>
      </tr>
      </tbody>
    </table>
    <b-button v-if="!loading" variant="primary" class="mt-3" @click="editSkuConfigs">
      <span id="txt_addButton">Edit distribution</span>
    </b-button>
  </b-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'sku-configurations',
  async mounted() {
    await this.loadProductTypes();
    await this.triggerProductTypeChange(this.selected);
  },
  data() {
    return {
      loading: true,
      selected: 1,
      skuOptions: [],
      productTypeOptions: [],
      isHovered: false,
    };
  },
  methods: {
    async triggerProductTypeChange(productType) {
      await this.loadSkuTable(productType);
    },
    editSkuConfigs() {
      this.$router.push({
        name: 'SKUConfigsEdit',
        params: {
          productType: this.selected,
        },
      });
    },
    async loadProductTypes() {
      this.loading = true;
      await axios
        .get('v1/skus/defaults')
        .then(response => {
          this.productTypeOptions = response.data.map(productType => ({
            text: productType.productTypeName,
            value: productType.ID,
          }));
        })
        .catch(response => {
          this.$noty.error('Unable to load product types');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    async loadSkuTable(productTypeId) {
      this.loading = true;
      this.skuOptions = [];
      await axios
        .get(`v1/skus/${productTypeId}/default-distributions/`)
        .then(response => {
          this.skuOptions = response.data.product_type_sku;
        })
        .catch(response => {
          this.$noty.error('Unable to load SKU distributions configs');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    handleInfoHover(hovered) {
      this.isHovered = hovered;
    },
  },
};
</script>
